<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.reserved_goods") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center" style="min-width: 100px">
                <SelectWarehouse
                  :label="$t('labels.warehouse_1')"
                  :placeholder="$t('labels.warehouse_1')"
                  name="id_warehouse"
                  sort-name="warehouse_code"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <SelectEmployee
                  :label="$t('labels.employee_create')"
                  :placeholder="$t('labels.employee_create')"
                  name="id_creator"
                  sort-name="creator_name"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <DateRangeFilter
                  :label="$t('labels.reserved_at')"
                  :placeholder="$t('labels.reserved_at')"
                  name="created_at"
                  sort-name="created_at"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <template v-if="filters.status === 0">
                <th class="text-center" style="min-width: 100px">
                  <DateRangeFilter
                    :label="$t('labels.cancel_reserved_goods_date')"
                    :placeholder="$t('labels.cancel_reserved_goods_date')"
                    name="cancel_at"
                    sort-name="cancel_at"
                    :sorting="filters.sort_by"
                    @onFilter="onFilterChange"
                    @onSort="onSortChange"
                  />
                </th>
              </template>
              <th class="text-center" style="min-width: 100px">
                <InputFilterFromTo
                  :key="filters.status"
                  :label="$t('labels.quantity')"
                  :placeholder="$t('labels.quantity')"
                  :name="
                    filters.status == 1
                      ? 'remaining_quantity'
                      : 'cancel_quantity'
                  "
                  :sort-name="
                    filters.status == 1
                      ? 'remaining_quantity'
                      : 'cancel_quantity'
                  "
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.note')"
                  :placeholder="$t('labels.note')"
                  name="note"
                  sort-name="note"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.barcode')"
                  :placeholder="$t('labels.barcode')"
                  name="barcode"
                  sort-name="barcode"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.sku')"
                  :placeholder="$t('labels.sku')"
                  name="sku"
                  sort-name="sku"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <SelectFilter
                  :label="$t('labels.image')"
                  :placeholder="$t('labels.image')"
                  name="image"
                  sort-name="url_images"
                  :options="imageOptions"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.name')"
                  :placeholder="$t('labels.name')"
                  name="name"
                  sort-name="name"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.goods_description')"
                  :placeholder="$t('labels.goods_description')"
                  name="description"
                  sort-name="description"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <template v-if="filters.status === 1">
                <th class="text-center" style="min-width: 100px"></th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="text-center">
              <td class="">{{ item.warehouse_code }}</td>
              <td class="">{{ item.creator_name }}</td>
              <td class="">
                {{ item.created_at ? formatDateTime(item.created_at) : "" }}
              </td>
              <template v-if="filters.status === 0">
                <td class="">
                  {{ item.cancel_at ? formatDateTime(item.cancel_at) : "" }}
                </td>
              </template>
              <td class="">
                <span
                  class="cursor-pointer primary--text text-decoration-underline"
                  @click="showHistoryDialog(item)"
                >
                  {{ item.remaining_quantity || item.quantity }}
                </span>
              </td>
              <td class="">{{ item.note }}</td>
              <td class="">{{ item.customer_goods_barcode }}</td>
              <td class="">{{ item.sku }}</td>
              <td class="">
                <ImageViewer v-if="item.url_images" :url="item.url_images" />
              </td>
              <td class="">{{ item.name }}</td>
              <td class="">{{ item.description }}</td>
              <template v-if="filters.status === 1">
                <td class="">
                  <v-btn
                    x-small
                    color="warning"
                    v-if="isShowBtnCancel(item)"
                    @click="showDialogReservedCancel(item)"
                  >
                    {{ $t("labels.cancel_reserved_goods") }}
                  </v-btn>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <div>
        <v-btn
          small
          :color="btnStatusColor(1)"
          @click="changeStatus(1)"
          class="mr-2"
        >
          {{ $t("labels.reserved_goods_status_1") }}
        </v-btn>
        <v-btn small :color="btnStatusColor(0)" @click="changeStatus(0)">
          {{ $t("labels.reserved_goods_status_0") }}
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn small color="success" @click="downloadExcel">
        <v-icon>mdi-download</v-icon> {{ $t("labels.excel") }}
      </v-btn>
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="6"
      ></v-pagination>
      <v-spacer></v-spacer>
      <v-btn
        color="purple"
        small
        outlined
        @click="showDialogReservedExcel"
        class="mr-2"
      >
        {{ $t("labels.reserved_goods_excel") }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogReservedExcel" max-width="100vw">
      <ReservedExcel
        v-if="dialogReservedExcel"
        @cancel="hideDialogReservedExcel"
        @refreshData="refreshData"
      />
    </v-dialog>

    <v-dialog v-model="dialogReservedCancel" max-width="960px">
      <ReservedCancel
        v-if="dialogReservedCancel"
        @cancel="hideDialogReservedCancel"
        :item="selectedItem"
        @refreshData="refreshData"
      />
    </v-dialog>

    <v-dialog v-model="dialogHistory" max-width="480px">
      <ReservedCancelHistory
        v-if="dialogHistory"
        @cancel="hideHistoryDialog"
        :item="selectedItem"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { IMAGE_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "ReservedQuantity",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    ReservedExcel: () => import("@/components/goods/ReservedExcel"),
    ReservedCancel: () => import("@/components/goods/ReservedCancel"),
    ReservedCancelHistory: () =>
      import("@/components/goods/ReservedCancelHistory"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    isLoading: false,
    hasChange: false,
    dialogReservedExcel: false,
    dialogReservedCancel: false,
    dialogHistory: false,
    selectedItem: {},
    page: 1,
    totalPage: 1,
    filters: {
      status: 1,
    },
    imageOptions: [...IMAGE_OPTIONS],
    statusOptions: [
      {
        text: "Đang giữ",
        value: 1,
      },
      {
        text: "Đã nhả",
        value: 0,
      },
    ],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    closeDialog() {
      if (this.hasChange) {
        this.$emit("refreshData", true);
      }
      this.$emit("cancel", true);
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    changeStatus(status) {
      const filters = { status };
      this.filters = { ...filters };
    },
    btnStatusColor(status) {
      if (status === this.filters.status) {
        return "primary";
      }
      return "default";
    },
    refreshData() {
      this.hasChange = true;
      this.getList();
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-reserved-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 100),

    isShowBtnCancel(item) {
      const { me } = window;
      return item.status === 1 && item.id_creator === me.identity_id;
    },

    showDialogReservedExcel() {
      this.dialogReservedExcel = true;
    },
    hideDialogReservedExcel() {
      this.dialogReservedExcel = false;
    },

    showDialogReservedCancel(item) {
      this.selectedItem = { ...item };
      this.dialogReservedCancel = true;
    },
    hideDialogReservedCancel() {
      this.selectedItem = {};
      this.dialogReservedCancel = false;
    },

    showHistoryDialog(item) {
      this.selectedItem = { ...item };
      this.dialogHistory = true;
    },

    hideHistoryDialog() {
      this.selectedItem = {};
      this.dialogHistory = false;
    },

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-giu-ton.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-reserved-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async cancel(item) {
      if (!confirm(this.$t("messages.are_you_confirm_cancel_reserved_goods"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/cancel-goods-reserved", {
          id: item.id,
        });
        this.isLoading = false;
        this.$vToastify.success(
          this.$t("messages.cancel_reserved_goods_success")
        );
        this.hasChange = true;
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
